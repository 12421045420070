<template>
    <v-container fluid class="mx-0 px-0">
        <v-row justify="center">
            <v-col cols="12" md="10" align="center">
                <v-card
                    class="px-10 py-10"
                >
                    <v-row v-for="(header, index) in headers" :key="index" :class="index < headers.length - 1 ? 'bottom-border' : ''">
                        <v-col cols="6" md="3" align="start" class="custom-orange-text font-weight-bold">
                            {{ header.text }}
                        </v-col>
                        <v-col cols="6" md="3" align="start" class="font-weight-bold secondary-font">
                            {{ stats[header.value].value }}
                        </v-col>
                        <v-col cols="6" md="3" align="start" class="font-weight-bold secondary-font">
                            {{ getDate(header.value) }}
                        </v-col>
                        <v-col cols="6" md="3" align="start" class="secondary-font">
                            <span class="font-weight-bold"> {{ team }} </span>
                            <span class="grey--text">vs</span>
                            <span class="font-weight-bold"> {{ stats[header.value].oponnent }}</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import momentTimezone from 'moment-timezone'

    export default {
        props: {
            stats: {
                type: Object,
                default: null
            },
            team: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                headers: [
                    { text: 'Points', value: 'points' },
                    { text: 'Total Rebounds', value: 'rebounds' },
                    { text: 'Assists', value: 'assists' },
                    { text: '2-Pointers Made', value: 'twoPointsMade' },
                    { text: '3-Pointers Made', value: 'threePointsMade' },
                    { text: 'Free Throws Made', value: 'freeThrowsMade' },
                    { text: 'Steals', value: 'steals' },
                    { text: 'Blocks', value: 'blockedShots' },
                    { text: 'Turnovers', value: 'turnovers' }
                ]
            }
        },
        methods: {
            getDate(key) {
                return momentTimezone(this.stats[key].date).tz('Europe/Athens').locale('el-GR').format('MMMM D YYYY, hh:mm').toLocaleString('el-GR', 'full')
            }
        }
    }
</script>

<style scoped>
.bottom-border {
    border-bottom: thin solid rgb(231, 225, 225) !important;
}
</style>
