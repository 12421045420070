<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-carousel
                    v-if="$vuetify.breakpoint.mobile"
                    hide-delimiters
                    height="430"
                >
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn
                            icon
                            color="transparent"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-left </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn
                            icon
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-right </v-icon>
                        </v-btn>
                    </template>
                    <v-carousel-item
                        v-for="(moment, index) in moments"
                        :key="index"
                    >
                        <v-row justify="center" align="center">
                            <v-col
                                cols="9"
                                align="center"
                            >
                                <v-card class="rounded-xl iframe-card">
                                    <v-btn icon @click="shareViaWebShare(moment)">
                                        Share on socials! <v-icon color="orange"> mdi-share-variant </v-icon>
                                    </v-btn>
                                    <iframe class="iframe-fit rounded-xl" width="234" height="417" :src="moment.replace('shorts', 'embed')" title="11 May 2023" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
                <v-carousel
                    v-else
                    hide-delimiters
                    height="430"
                >
                    <template v-slot:prev="{ on, attrs }">
                        <v-btn
                            icon
                            color="transparent"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-left </v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:next="{ on, attrs }">
                        <v-btn
                            icon
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon x-large color="orange"> mdi-chevron-right </v-icon>
                        </v-btn>
                    </template>
                    <v-carousel-item
                        v-for="(group, groupIndex) in formatMomentsArray()"
                        :key="groupIndex"
                    >
                        <v-row justify="center" align="center">
                            <v-col
                                v-for="(moment, index) in group"
                                :key="index"
                                cols="3"
                                align="center"
                            >
                                <v-card class="rounded-xl iframe-card">
                                    <v-btn icon @click="shareViaWebShare(moment)">
                                        Share on socials! <v-icon color="orange"> mdi-share-variant </v-icon>
                                    </v-btn>
                                    <iframe class="iframe-fit" :src="moment.replace('shorts', 'embed')" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            moments: {
                type: Array,
                default: null
            }
        },
        data() {
            return {
            }
        },
        computed: {
            webShareApiSupported() {
                return navigator.share
            }
        },
        methods: {
            formatMomentsArray() {
                const data = []
                let index = 1
                let temp = []
                this.moments.forEach(element => {
                    temp.push(element)
                    if (index % 4 === 0 || index === this.moments.length) {
                        data.push(temp)
                        temp = []
                    }
                    index++
                })
                return data
            },
            shareViaWebShare(moment) {
                navigator.share({
                    title: 'Another Big Moment',
                    text: 'Another Big Moment',
                    url: moment
                })
            }
        }
    }

</script>

<style scoped>
.v-carousel {
    height: 430px !important;
}

.iframe-card {
    width: 240px;
    height: 417px;
}

.iframe-fit {
    height: 92%;
    width: 100%;
}

</style>
