import api from '@/utils/api'

export default {
    getConfigField(configField) {
        return api.get(`config/${configField}`)
    },

    getConfig() {
        return api.get('config')
    }
}
