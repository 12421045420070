<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" align="center">
                <v-card
                    :width="!$vuetify.breakpoint.mobile ? '700px' : '100%'"
                    :color="player.team.color ? player.team.color : 'orange'"
                    class="rounded-lg"
                >
                    <img
                        v-if="player.image && !showIcon"
                        :width="!$vuetify.breakpoint.mobile ? '300px' : '200px'"
                        :src="`${assetsUrl}/${player.image}`"
                        contain
                        @error="showIcon = true"
                    >
                    <v-icon v-else style="font-size: 150px;" color="white" class="py-10">
                        mdi-account
                    </v-icon>
                </v-card>
                <v-card
                    :width="!$vuetify.breakpoint.mobile ? '700px' : '100%'"
                    class="secondary-font"
                >
                    <v-row justify="center" align="center" class="mx-0 px-0">
                        <v-col cols="12" style="background-color: #dbdbdd;" class="rounded">
                            <match-type-choice @setType="setType" />
                        </v-col>
                        <v-col cols="6" align="end">
                            <img
                                v-if="player.team.logo"
                                width="100px"
                                :src="`${assetsUrl}/${player.team.logo}`"
                                contain
                            >
                            <v-icon v-else style="font-size: 80px;" class="orange--text mt-3"> mdi-basketball </v-icon>
                        </v-col>
                        <v-col cols="6" align="start">
                            <span class="black--text" :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'" style="font-family: Mansalva, Arial, sans-serif !important;">{{ player.team.name }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="px-5">
                        <v-col cols="4" md="2" class="bottom-border" align="start">
                            <span class="black--text text-h6">Όνομα</span>
                        </v-col>
                        <v-col cols="8" md="4" class="bottom-border">
                            <span class="black--text text-subtitle-1"> {{ `${player.name} ${player.surname}` }} </span>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border" align="start">
                            <v-icon color="black">
                                mdi-pound
                            </v-icon>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border">
                            <span class="black--text text-subtitle-1"> {{ player.jersey }} </span>
                        </v-col>
                    </v-row>
                    <v-row class="px-5">
                        <v-col cols="6" md="3" class="bottom-border" align="start">
                            <span class="black--text text-h6">Θέση</span>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border">
                            <span class="black--text text-subtitle-1"> {{ player.position }} </span>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border" align="start">
                            <span class="black--text text-h6">Ομάδα</span>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border">
                            <span class="black--text text-subtitle-1"> {{ player.team.name }} </span>
                        </v-col>
                    </v-row>
                    <v-row class="px-5">
                        <v-col cols="6" md="3" class="bottom-border" align="start">
                            <span class="black--text text-h6"> Ύψος </span>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border">
                            <span class="black--text text-subtitle-1"> {{ player.height }} </span>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border" align="start">
                            <span class="black--text text-h6">Ημ. Γέννησης</span>
                        </v-col>
                        <v-col cols="6" md="3" class="bottom-border">
                            <span class="black--text text-subtitle-1"> {{ player.birthday }} </span>
                        </v-col>
                    </v-row>
                    <v-row v-if="type !== 'cup'" class="px-5">
                        <v-col cols="6" md="3" class="black--text text-h6" align="start">
                            <span class="black--text text-h6"> Χαρτί Γιατρού </span>
                        </v-col>
                        <v-col cols="6" md="3">
                            <span class="black--text text-subtitle-1">
                                <v-icon large :class="player.doctorCheck ? 'green--text' : 'red--text'">
                                    {{ player.doctorCheck ? 'mdi-check-outline' : 'mdi-close-circle-outline' }}
                                </v-icon>
                            </span>
                        </v-col>
                        <v-col cols="6" md="3" class="black--text text-h6" align="start">
                            <span class="black--text text-h6"> Παιχνίδια </span>
                        </v-col>
                        <v-col cols="6" md="3">
                            <span class="black--text text-subtitle-1" :class="player.stats.filter(element => ((element.phase === 'regular_season' || element.phase === 'placements') || (this.type === 'cup' && element.cup))).length < noOfMatches ? 'red--text' : 'green--text'"> {{ `${player.stats.filter(element => ((element.phase === 'regular_season' || element.phase === 'placements') || (this.type === 'cup' && element.cup))).length} / ${noOfMatches}` }} </span>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card
                    :width="!$vuetify.breakpoint.mobile ? '700px' : '100%'"
                    elevation="0"
                >
                    <v-row class="mx-0 px-0 my-0">
                        <v-col cols="12" class="mx-0 px-0">
                            <v-tabs
                                v-model="tab"
                                centered
                                class="rounded"
                            >
                                <v-tabs-slider color="orange" />
                                <v-tab v-for="(item, index) in player.highlights ? items.concat('BIG Moments') : items" :key="index">{{ item }}</v-tab>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <player-stats v-if="tab === 0" :total-stats="getCummulativeStats()" :avg-stats="getAverageStats()" />
        <game-stats v-if="tab === 0" :stats="player.stats.filter(element => (element.phase === this.type || (this.type === 'cup' && element.cup)))" />
        <player-records v-else-if="tab === 1" :stats="getMaxStats()" :team="player.team.name" />
        <player-moments v-else :moments="player.highlights" />
    </v-container>
</template>

<script>
    import PlayerStats from '@/components/PlayerStats'
    import PlayerMoments from '@/components/PlayerMoments'
    import GameStats from '@/components/GameStats'
    import PlayerRecords from '@/components/PlayerRecords'
    import playerService from '@/services/player'
    import configService from '@/services/config'
    import MatchTypeChoice from '@/components/MatchTypeChoice'

    export default {
        components: { PlayerStats, GameStats, PlayerRecords, PlayerMoments, MatchTypeChoice },
        data() {
            return {
                player: {},
                items: ['Stats', 'Records'],
                tab: null,
                assetsUrl: 'https://storage.googleapis.com',
                showIcon: false,
                noOfMatches: 0,
                type: 'regular_season',
                matchTypes: ['placements', 'regular_season', 'playoffs', 'cup']
            }
        },
        watch: {
            '$route.params.id': {
                handler() {
                    this.getPlayer(this.$route.params.id)
                }
            },
            'player.noOfMatches': {
                handler() {
                    this.getNoOfMatches()
                },
                deep: true
            }
        },
        created() {
            this.getPlayer(this.$route.params.id)
        },
        methods: {
            setType(option) {
                this.type = this.matchTypes[option]
            },
            getPlayer(playerId) {
                playerService.getPlayer(playerId).then(resp => {
                    this.player = resp.data
                })
            },
            getCummulativeStats() {
                const stats = {
                    points: 0,
                    rebounds: 0,
                    assists: 0,
                    steals: 0,
                    turnovers: 0,
                    blockedShots: 0,
                    twoPointsMade: 0,
                    twoPointAttempts: 0,
                    threePointsMade: 0,
                    threePointAttempts: 0,
                    freeThrowsMade: 0,
                    freeThrowAttempts: 0,
                    offensiveRebounds: 0,
                    defensiveRebounds: 0,
                    personalFouls: 0
                }
                this.player.stats.filter(element => (element.phase === this.type || (this.type === 'cup' && element.cup))).forEach(element => {
                    for (const key of Object.keys(stats)) {
                        stats[key] += element[key]
                    }
                })
                return stats
            },
            getAverageStats() {
                const stats = {
                    points: 0,
                    rebounds: 0,
                    assists: 0,
                    steals: 0,
                    turnovers: 0,
                    blockedShots: 0,
                    twoPointsMade: 0,
                    twoPointAttempts: 0,
                    threePointsMade: 0,
                    threePointAttempts: 0,
                    freeThrowsMade: 0,
                    freeThrowAttempts: 0,
                    offensiveRebounds: 0,
                    defensiveRebounds: 0,
                    personalFouls: 0
                }
                this.player.stats.filter(element => (element.phase === this.type || (this.type === 'cup' && element.cup))).forEach(element => {
                    for (const key of Object.keys(stats)) {
                        stats[key] += element[key]
                    }
                })

                Object.keys(stats).forEach(element => {
                    if (this.player.stats.filter(element => (element.phase === this.type || (this.type === 'cup' && element.cup))).length) {
                        stats[element] = (stats[element] / this.player.stats.filter(element => (element.phase === this.type || (this.type === 'cup' && element.cup))).length).toFixed(1)
                    } else {
                        stats[element] = 0
                    }
                })

                return stats
            },
            getMaxStats() {
                const stats = {
                    points: {
                        value: 0,
                        oponnent: null,
                        date: null,
                        text: 'points'
                    },
                    rebounds: {
                        value: 0,
                        oponnent: null,
                        date: null,
                        text: 'reb.'
                    },
                    assists: {
                        value: 0,
                        oponnent: null,
                        date: null,
                        text: 'assists'
                    },
                    steals: {
                        value: 0,
                        oponnent: null,
                        date: null,
                        text: 'steals'
                    },
                    turnovers: {
                        value: 0,
                        oponnent: null,
                        date: null,
                        text: 'turnov.'
                    },
                    blockedShots: {
                        value: 0,
                        oponnent: null,
                        date: null,
                        text: 'blocks'
                    },
                    twoPointsMade: {
                        value: 0,
                        oponnent: null,
                        date: null
                    },
                    threePointsMade: {
                        value: 0,
                        oponnent: null,
                        date: null
                    },
                    freeThrowsMade: {
                        value: 0,
                        oponnent: null,
                        date: null
                    }
                }
                this.player.stats.filter(element => (element.phase === this.type || (this.type === 'cup' && element.cup))).forEach(element => {
                    for (const key of Object.keys(stats)) {
                        if (element[key] >= stats[key].value) {
                            stats[key] = {
                                value: element[key],
                                oponnent: element.opponentTeamName,
                                date: element.matchDatetime
                            }
                        }
                    }
                })

                return stats
            },
            getNoOfMatches() {
                configService.getConfigField(this.player.noOfMatches).then(resp => {
                    this.noOfMatches = resp.data[this.player.noOfMatches]
                })
            }
        }
    }
</script>

<style scoped>

.text-subtitle-1 {
    font-size: 1.1rem !important;
    font-weight: 400;
    line-height: 1.75rem;
    letter-spacing: 0.009375em !important;
}

.text-h6 {
    font-size: 1.25rem !important;
    letter-spacing: 0.0125em !important;
    line-height: 2rem;
}

.v-tab {
    text-transform: none !important;
    font-weight: bolder;
    font-size: 15px;
    color: #757578 !important;
}

.v-tab::before {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
}

.v-tab--active {
    color: orange !important;
}

.bottom-border {
    border-bottom: 1px solid rgb(231, 225, 225);
}
</style>
