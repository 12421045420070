import api from '@/utils/api'

export default {
    getPlayers(page, size, query = {}) {
        let filter = ''
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        filter += '"active": true'
        const range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get('players' + (page ? range : '') + (filter ? `&filter=${encodeURIComponent('{' + filter + '}')}` : ''))
    },

    getPlayer(id) {
        return api.get(`players/${id}`)
    }
}
